import LogoPng from '../static/png/logo.png';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from '../component/Search';
import { Categories } from '../component/Categories';
import { trackVisit } from '../api';


export const Open = () => {
    const navigate = useNavigate();

    const [categories, setCategories] = useState<[string, string][]>([]);

    const onSubmit = (t: string, c: [string, string][]) => {
        setCategories([]);
        navigate(`/search/ml?text=${t}&categories=${c.map((d) => [d[1], d[0]].join(' ')).join(',')}`);
    }

    useEffect(() => {
        const work = async () => {
            try {
                let hostname = '';
                if (!!document.referrer) {
                    const url = new URL(document.referrer);
                    hostname = url.hostname;
                }

                await trackVisit(hostname, '/', undefined, undefined);
            }
            catch { }
        }

        work();
    }, []);

    return (
        <div className='wrapper'>
            <main className='page-main'>
                <section className='searcher'>
                    <div className='searcher__container'>
                        <div className='searcher__title title'>
                            <img className='searcher__logo' src={LogoPng} />
                        </div>
                        <div className='searcher__text'>
                            Inovation OnlyFans search platform
                        </div>
                    </div>
                </section>
                <section className='search-module'>
                    <div className='search-module__container'>
                        <Search page='main' onSubmit={(text) => onSubmit(text, categories)} />
                        <div className='search-module__links links'>
                            <button
                                onClick={(e) => { e.preventDefault(); onSubmit('', [['order', 'top']]) }}
                                type='button'
                                className='links__item item-link'
                            >
                                <span className='item-link__text'>
                                    Top
                                </span>
                            </button>
                            <button
                                onClick={(e) => { e.preventDefault(); onSubmit('', [['order', 'new']]) }}
                                type='button'
                                className='links__item item-link'
                            >
                                <span className='item-link__text'>
                                    New
                                </span>
                            </button>
                            <button
                                onClick={(e) => { e.preventDefault(); onSubmit('', [['subscription', 'free']]) }}
                                type='button'
                                className='links__item item-link'
                            >
                                <span className='item-link__text'>
                                    Free
                                </span>
                            </button>
                            <Categories categories={categories} onChange={(c) => setCategories(c)} />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}