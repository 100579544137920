import AdWebp from '../static/webp/ad.webp';
import { HeartSvg } from '../static/svg/HeartSvg';
import { PhotoSvg } from '../static/svg/PhotoSvg';
import { VideoSvg } from '../static/svg/VideoSvg';
import { LabelSvg } from '../static/svg/LabelSvg';
import { InstagramSvg } from '../static/svg/InstagramSvg';
import { TwitterSvg } from '../static/svg/TwitterSvg';
import { TiktokSvg } from '../static/svg/TiktokSvg';
import React, { useEffect, useRef } from 'react';
import { ISearchModel, trackClick, trackView } from '../api';


interface IModelListItemProperties {
    model: ISearchModel;
}

const ModelListItem = ({ model }: IModelListItemProperties) => {
    const modelLink = (!model.apath && !model.trial)
        ? `https://onlyfans.com/${model.nickname}`
        : !model.trial
            ? `https://onlyfans.com/${model.nickname}/${model.apath}`
            : `https://onlyfans.com/action/trial/${model.trial}`;

    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
        if (model.advertisement) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        const work = async () => {
                            try {
                                await trackView(model.advertisement, !model.advertisement ? model.nickname : undefined);
                            }
                            catch { }
                        }

                        work();
                    }
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 1,
                }
            );

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            }
        }

    }, [])

    const onClick = () => {
        const work = async () => {
            try {
                await trackClick(model.advertisement, !model.advertisement ? model.nickname : undefined);
            }
            catch { }
        }

        work();
    }

    return (
        <div className='cards__wrapper'>
            <div className='cards__item item-card' ref={ref}>
                <a className='item-card__image' href={modelLink} target='_blank' onClick={onClick}>
                    <img src={`${model.avatar}`} />
                </a>
                <div className='item-card__body info-model'>
                    <div className='info-model__name name'>
                        <div className='name__body'>
                            <a className='name__nickname' href={modelLink} target='_blank' onClick={onClick}>
                                {model.name}
                            </a>
                            <div className='name__row'>
                                <a className='name__link' href={modelLink} target='_blank' onClick={onClick}>
                                    {`onlyfans.com > ${model.nickname}`}
                                </a>
                                {
                                    !!model.advertisement &&
                                    <img className='name__add' src={AdWebp} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='info-model__values item-values'>
                        <div className='item-values__item likes'>
                            <HeartSvg />
                            <p className='item-values__ammount'>
                                {model.like_count}
                            </p>
                        </div>
                        <div className='item-values__item photos'>
                            <PhotoSvg />
                            <p className='item-values__ammount'>
                                {model.photo_count}
                            </p>
                        </div>
                        <div className='item-values__item videos'>
                            <VideoSvg />
                            <p className='item-values__ammount'>
                                {model.video_count}
                            </p>
                        </div>
                        <div className='item-values__item price'>
                            <LabelSvg />
                            <p className='item-values__ammount'>
                                {Number(model.subscribe_cost) != 0 ? model.subscribe_cost : 'FREE'}
                            </p>
                        </div>
                    </div>
                    <div className='info-model2__text description'>
                        <p className='description__text'>
                            {model.about}
                        </p>
                    </div>
                    <div className='info-mode2__social social'>
                        {
                            !!model.instagram &&
                            <a className='social__link' href={`https://instagram.com/${model.instagram}`} target='_blank' onClick={onClick}>
                                <InstagramSvg />
                            </a>
                        }
                        {
                            !!model.twitter &&
                            <a className='social__link' href={`https://twitter.com/${model.twitter}`} target='_blank' onClick={onClick}>
                                <TwitterSvg />
                            </a>
                        }
                        {
                            !!model.tiktok &&
                            <a className='social__link' href={`https://tiktok.com/${model.tiktok}`} target='_blank' onClick={onClick}>
                                <TiktokSvg />
                            </a>
                        }
                        <a className='social__link' href={modelLink} target='_blank' onClick={onClick}>
                            <p className='description__text' style={{ color: '#ff8c00' }}>
                                CLICKS TO SUBSCRIBE
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface IModelListProperties {
    modelList: ISearchModel[];
}

export const ModelList = ({ modelList }: IModelListProperties) => {
    return (
        <section className='cards'>
            <div className='cards__container'>
                <div className='cards__body'>
                    {modelList.map((model, i) => <ModelListItem key={i} model={model} />)}
                </div>
            </div>
        </section>
    );
}