import AdWebp from '../static/webp/ad.webp';
import { TwitterSvg } from '../static/svg/TwitterSvg';
import { InstagramSvg } from '../static/svg/InstagramSvg';
import { TiktokSvg } from '../static/svg/TiktokSvg';
import { HeartSvg } from '../static/svg/HeartSvg';
import { PhotoSvg } from '../static/svg/PhotoSvg';
import { VideoSvg } from '../static/svg/VideoSvg';
import { LabelSvg } from '../static/svg/LabelSvg';
import React from 'react';
import { ISearchModel, trackClick } from '../api';


interface IModelProperties {
    model: ISearchModel;
}

export const Model = ({ model }: IModelProperties) => {
    const modelLink = (!model.apath && !model.trial)
        ? `https://onlyfans.com/${model.nickname}`
        : !model.trial
            ? `https://onlyfans.com/${model.nickname}/${model.apath}`
            : `https://onlyfans.com/action/trial/${model.trial}`;

    const onClick = () => {
        const work = async () => {
            try {
                await trackClick(model.advertisement, !model.advertisement ? model.nickname : undefined);
            }
            catch { }
        }

        work();
    }

    return (
        <section className='model-page'>
            <div className='model-page__container'>
                <div className='model-page__body'>
                    <div className='model-page__image'>
                        <a href={modelLink} target='_blank' onClick={onClick}>
                            <img src={model.avatar} />
                        </a>
                    </div>
                    <div className='model-page__info info-model'>
                        <div className='info-model__name name'>
                            <div className='name__body'>
                                <a className='name__nickname' href={modelLink} target='_blank' onClick={onClick}>
                                    {model.name}
                                </a>
                                <div>
                                    <a className='name__link' href={modelLink} target='_blank' onClick={onClick}>
                                        {`onlyfans.com > ${model.nickname}`}
                                    </a>
                                    {
                                        !!model.advertisement &&
                                        <img className='name__add' src={AdWebp} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='info-model__values item-values'>
                            <div className='item-values__item likes'>
                                <HeartSvg />
                                <p className='item-values__ammount'>{model.like_count}</p>
                            </div>
                            <div className='item-values__item photos'>
                                <PhotoSvg />
                                <p className='item-values__ammount'>{model.photo_count}</p>
                            </div>
                            <div className='item-values__item videos'>
                                <VideoSvg />
                                <p className='item-values__ammount'>{model.video_count}</p>
                            </div>
                            <div className='item-values__item price'>
                                <LabelSvg />
                                <p className='item-values__ammount'>
                                    {0 < Number(model.subscribe_cost) ? model.subscribe_cost : 'FREE'}
                                </p>
                            </div>
                        </div>
                        <div className='info-model__text description'>
                            <p className='description__text'>{model.about}</p>
                        </div>
                        <div className='info-model__social social'>
                            {
                                !!model.instagram &&
                                <a className='social__link' href={`https://instagram.com/${model.instagram}`} target='_blank' onClick={onClick}>
                                    <InstagramSvg />
                                </a>
                            }
                            {
                                !!model.twitter &&
                                <a className='social__link' href={`https://twitter.com/${model.twitter}`} target='_blank' onClick={onClick}>
                                    <TwitterSvg />
                                </a>
                            }
                            {
                                !!model.tiktok &&
                                <a className='social__link' href={`https://tiktok.com/${model.tiktok}`} target='_blank' onClick={onClick}>
                                    <TiktokSvg />
                                </a>
                            }
                            <a className='social__link' href={modelLink} target='_blank' onClick={onClick}>
                                <p className='description__text' style={{ color: '#ff8c00' }}>
                                    CLICKS TO SUBSCRIBE
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}