import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


const ArroSvg = () => {
    return (
        <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='SVGRepo_bgCarrier' strokeWidth='0' />
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
            <g id='SVGRepo_iconCarrier'>
                <g id='Menu / Close_LG'>
                    <path id='Vector' d='M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001' stroke='#000000' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                </g>
            </g>
        </svg>
    );
}

const LensSvg = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path d='M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z' />
        </svg>
    );
}

interface ISearchProperties {
    page: 'main' | 'search';
    onSubmit: (t: string) => void;
}

export const Search = ({ page, onSubmit }: ISearchProperties) => {
    const location = useLocation();

    const [text, turnText] = useState<string>('');

    useEffect(() => {
        if (!location.search) {
            return;
        }

        const url = new URL(`https://onlyfiesta.club/search${location.search}`);
        if (!url.searchParams.get('text')) {
            return;
        }

        turnText(url.searchParams.get('text'));
    }, [location]);

    return (
        <form
            className={`${page == 'main' ? 'search-module' : 'header'}__form form`}
            onSubmit={(e) => { e.preventDefault(); onSubmit(text); }}
        >
            <input
                className='form__input-search'
                type='text'
                value={text}
                onChange={(e) => {turnText(e.target.value)}}
            />
            <button className='form__button' type='submit'>
                <LensSvg />
            </button>
            <button className='form__cancel' type='button' onClick={(e) => { e.preventDefault(); turnText('') }}>
                <ArroSvg />
            </button>
        </form>
    );
} 