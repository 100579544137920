import LogoPng from '../static/png/logo.png';
import { ToggleSvg } from '../static/svg/ToggleSvg';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Search } from './Search';
import { Categories } from './Categories';

export const Header = () => {
    const location = useLocation();

    const navigate = useNavigate();

    const ref = useRef<HTMLElement>(null);

    const [open, turnOpen] = useState<boolean>(false);
    const [categories, turnCategories] = useState<[string, string][]>([]);

    useEffect(() => {
        if (!location.search) {
            return;
        }

        const url = new URL(`https://onlyfiesta.club/search${location.search}`);
        if (!url.searchParams.get('categories')) {
            return;
        }

        console.log(url.searchParams.get('categories').split(',').filter((c) => c != 'top order' && c != 'new order'))

        turnCategories(url.searchParams.get('categories').split(',').filter((c) => c != 'top order' && c != 'new order').map((c) => c.split(' ').reverse() as [string, string]));
    }, [location]);

    useEffect(() => {
        const work = (event) => {
            if (!ref || ref.current.contains(event['target'])) {
                return;
            }

            turnOpen(false);
        }

        document.addEventListener('click', work);

        return () => document.removeEventListener('click', work);
    }, []);

    const onSubmit = (t: string, c: [string, string][]) => {
        turnOpen(false);

        turnCategories([]);

        navigate(`/search/ml?text=${t}&categories=${c.map((d) => [d[1], d[0]].join(' ')).join(',')}`);
    }

    return (
        <header className={`header ${open ? 'open' : ''}`} ref={ref}>
            <div className='header__main-logo' />
            <div className='header__container'>
                <div className='header__body'>
                    <div className='header__top'>
                        <Link className='header__logo' to='/'>
                            <img src={LogoPng} />
                        </Link>
                        <div className='header__toggle' onClick={() => turnOpen(!open)}>
                            <ToggleSvg />
                        </div>
                    </div>
                    <div className='header__bottom'>
                        <Search page='search' onSubmit={(text: string) => onSubmit(text, categories)} />
                        <div className='header__links links'>
                            <button
                                onClick={(e) => { e.preventDefault(); onSubmit('', [['order', 'top']]) }}
                                type='button'
                                className='links__item item-link'
                            >
                                <span className='item-link__text'>
                                    Top
                                </span>
                            </button>
                            <button
                                onClick={(e) => { e.preventDefault(); onSubmit('', [['order', 'new']]) }}
                                type='button'
                                className='links__item item-link'
                            >
                                <span className='item-link__text'>
                                    New
                                </span>
                            </button>
                            <button
                                onClick={(e) => { e.preventDefault(); onSubmit('', [['subscription', 'free']]) }}
                                type='button'
                                className='links__item item-link'
                            >
                                <span className='item-link__text'>
                                    Free
                                </span>
                            </button>
                            <Categories categories={categories} onChange={(c) => turnCategories(c)} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}