import './App.scss';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Open } from './page/Open';
import { ML } from './page/ML';
import { MS } from './page/MS';
import { MA } from './page/MA';


const router = createBrowserRouter([
    {
        path: '/',
        Component: Open,
    },
    {
        path: '/search/ml',
        Component: ML,
    },
    {
        path: '/search/ms',
        Component: MS,
    },
    {
        path: '/search/ma',
        Component: MA,
    },
]);

export const App = () => {
    return (
        <RouterProvider router={router} />
    );
}