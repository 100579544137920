import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Header } from '../component/Header';
import { Model } from '../component/Model';
import { ISearchModel, getMA, trackVisit } from '../api';


export const MA = () => {
    const [searchParams, ssp] = useSearchParams();

    const [model, setModel] = useState<ISearchModel>(null);

    useEffect(() => {
        const work = async () => {
            const id = searchParams.get('id');
            if (!id) {
                alert('Query param [id] must be');
                return;
            }

            try {
                const r = await getMA(id);
                if (!r) {
                    alert('Model not found');
                    return;
                }

                try {
                    let hostname = '';
                    if (!!document.referrer) {
                        const url = new URL(document.referrer);
                        hostname = url.hostname;
                    }
    
                    await trackVisit(hostname, '/search/ma', id, undefined);
                }
                catch { }

                setModel(r);
            }
            catch {
                alert('Something wrong');
                return;
            }
        }

        work();
    }, []);

    return (
        <div className='wrapper'>
            <Header />
            <main className='page-model'>
                {
                    !!model &&
                    <Model model={model}/>
                }
            </main>
        </div>
    );
}