import React from 'react';


export const LabelSvg = () => {
    return (
        <svg className='item-values__image' xmlns='http://www.w3.org/2000/svg' width='24' height='24'
            viewBox='0 0 24 24' style={{ fill: 'rgba(0, 0, 0, 1)' }}>
            <path
                d='M11.707 2.293A.997.997 0 0 0 11 2H6a.997.997 0 0 0-.707.293l-3 3A.996.996 0 0 0 2 6v5c0 .266.105.52.293.707l10 10a.997.997 0 0 0 1.414 0l8-8a.999.999 0 0 0 0-1.414l-10-10zM13 19.586l-9-9V6.414L6.414 4h4.172l9 9L13 19.586z'>
            </path>
            <circle cx='8.353' cy='8.353' r='1.647'></circle>
        </svg>
    );
}