export const server = 'https://onlyfiesta.club/api';
//export const server = 'http://localhost:3000/api';

export interface ISearchModel {
    nickname: string;
    name: string;
    about: string;
    avatar: string;
    like_count: number;
    photo_count: number;
    video_count: number;
    subscribe_cost: number;
    instagram?: string;
    tiktok?: string;
    twitter?: string;
    apath?: string;
    trial?: string;
    advertisement?: string;
}

export const getML = async (search: string): Promise<ISearchModel[]> => {
    const r = await fetch(
        `${server}/search/ml${search}`,
        {method: 'GET', headers: { 'Content-Type': 'application/json' }}
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const m = await r.json() as ISearchModel[];
    return m;
}

export const getMS = async (id: string): Promise<ISearchModel> => {
    const r = await fetch(
        `${server}/search/ms/${id}`,
        {method: 'GET', headers: { 'Content-Type': 'application/json' }}
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const m = await r.json() as ISearchModel;
    return m;
}

export const getMA = async (id: string): Promise<ISearchModel> => {
    const r = await fetch(
        `${server}/search/ma/${id}`,
        {method: 'GET', headers: { 'Content-Type': 'application/json' }}
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const m = await r.json() as ISearchModel;
    return m;
}

export const trackVisit = async (domain: string, target: string, model_a_id?: string, model_s_id?: string): Promise<void> => {
    const body = { domain: domain, target: target };
    if (!!model_a_id) {
        body['model_a_id'] = model_a_id;
    }
    if (!!model_s_id) {
        body['model_s_id'] = model_s_id;
    }

    await fetch(
        `${server}/tracking/visit`,
        {method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)}
    );
}

export const trackView = async (model_a_id?: string, model_s_id?: string): Promise<void> => {
    const body = {};
    if (!!model_a_id) {
        body['model_a_id'] = model_a_id;
    }
    if (!!model_s_id) {
        body['model_s_id'] = model_s_id;
    }

    await fetch(
        `${server}/tracking/view`,
        {method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)}
    );
}

export const trackClick = async (model_a_id?: string, model_s_id?: string): Promise<void> => {
    const body = {};
    if (!!model_a_id) {
        body['model_a_id'] = model_a_id;
    }
    if (!!model_s_id) {
        body['model_s_id'] = model_s_id;
    }

    await fetch(
        `${server}/tracking/click`,
        {method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)}
    );
}